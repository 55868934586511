import React, { useState, useEffect, useRef, useCallback, act } from "react";

import "./App.css";
import "./AuraSpin.css";
import aurasData from "./auras.js";
import { AnimatePresence, easeInOut, motion } from "framer-motion";
import axios from "axios";
import LuckAnimation from "./TextAnimation.js";
import ClickBar from "./ClickBar.js";
import SpinButton from "./SpinButton.js";
import SellIcon from "./Icons/SellIcon.js";
import TakeIcon from "./Icons/TakeIcon.js";
import { Player } from "@lottiefiles/react-lottie-player";
import DucksIcon from "./Icons/DucksIcon.js";
import useToast from "./Toast/useToast";
import ToastNotification from "./Toast/ToastNotification";
import { Ripple, PopCoins, pop } from "./Effects.js";
import AurasIcon from "./Icons/AurasIcon.js";
import InventoryIcon from "./Icons/InventoryIcon.js";
import MiniGame from "./MiniGame.js";

import {
  getRarity,
  getRarityColor,
  getAuraByName,
  createQueue,
} from "./Functions";

import shopItems from "./shopItems.js";
import { getItemByName, getContrastColor } from "./Functions";
import { shopBuyPhrases, shopNotEnoughPhrases } from "./ShopPhrases.js";
import ShopIcon from "./Icons/ShopIcon.js";
import BlacksmithIcon from "./Icons/BlacksmithIcon.js";

import sewingAnim from "./sewing.json";
import alchemistAnim from "./potionSpin.json";

import {
  s_auraBg,
  s_blacksmith,
  s_buttonClick,
  s_charging,
  s_city,
  s_click01,
  s_coins,
  s_craftPotion,
  s_forging,
  s_gameBg,
  s_mainAura,
  s_newAura,
  s_newAura2,
  s_nope,
  s_sewing,
  s_shopBg,
  s_skeleton,
  s_spin2,
  s_spinError,
  s_tailor,
  s_takeAura,
  s_tapGameLoose,
  s_tapGamePoint,
  s_useItem,
  s_usePotion,
  spinSound,
} from "./Sounds.js";
import PotionContainer from "./PotionContainer.js";
import CityIcon from "./Icons/CityIcon.js";
import SpinningWindow from "./SpinningWindow.js";
import forgeItems from "./BlacksmithItems.js";
import tailorItems from "./TailorItems.js";
import ActiveItem from "./ActiveItem.js";
import ItemsInventory from "./ItemsInventory.js";
import AurasInventory from "./AurasInventory.js";
import Blacksmith from "./Blacksmith.js";
import TailorIcon from "./Icons/TailorIcon.js";
import alchemistItems from "./AlchemistItems.js";
import AlchemistIcon from "./Icons/AlchemistIcon.js";

const tg = window.Telegram.WebApp;

//////Рандомная аура
const getRandomAura = (weight) => {
  const newWeight = weight + 1;
  // Создаем новый массив, обрезая первые weight элементов
  const filteredAuras = aurasData.slice(newWeight - 1);

  // Если после обрезки массив пустой, возвращаем "None"
  if (filteredAuras.length === 0) {
    return "None";
  }

  // const weightExponent = 1 - 0.01 * weight; // Настраиваем вес
  const weightExponent = 1;
  const sumOfProbabilities = filteredAuras.reduce(
    (sum, aura) => sum + Math.pow(1 / aura.probability, weightExponent),
    0
  );

  const rand = Math.random() * sumOfProbabilities;
  let cumulativeProbability = 0;

  for (let aura of filteredAuras) {
    cumulativeProbability += Math.pow(1 / aura.probability, weightExponent);
    if (rand < cumulativeProbability) {
      return aura;
    }
  }

  return "None";
};

// const getRandomAura = (weight) => {
//   const randomRatio = Math.floor(Math.random() * (40 - 20 + 1)) + 20;
//   const minProbability = weight > 1 ? randomRatio : 1;
//   // Фильтруем ауры, оставляя только те, у которых probability >= weight * 100
//   const filteredAuras = aurasData.filter(
//     (aura) => aura.probability >= weight * minProbability
//   );

//   // Если нет аур, которые удовлетворяют условию, возвращаем "None"
//   if (filteredAuras.length === 0) {
//     return "None";
//   }

//   const weightExponent = 1 - 0.01 * weight;

//   const sumOfProbabilities = filteredAuras.reduce(
//     (sum, aura) => sum + Math.pow(1 / aura.probability, weightExponent),
//     0
//   );

//   const rand = Math.random() * sumOfProbabilities;
//   let cumulativeProbability = 0;

//   for (let aura of filteredAuras) {
//     cumulativeProbability += Math.pow(1 / aura.probability, weightExponent);
//     if (rand < cumulativeProbability) {
//       return aura;
//     }
//   }

//   return "None";
// };

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
  },
});

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const AuraRng = ({
  sendLocalProfileData,
  localProfile,
  t,
  sendSpinning,
  farm,
  socket,
  sendNowOpen,
  isAuraLoaded,
}) => {
  const [currentAura, setCurrentAura] = useState(null);
  const [spinAura, setSpinAura] = useState({
    title: "goodluck",
  });
  const [spinning, setSpinning] = useState(false);
  const [takeButtonDisabled, setTakeButtonDisabled] = useState(true);

  const [currentContainerStyle, setCurrentContainerStyle] =
    useState("current-container");
  const [tgId, setTgId] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [tgUsername, setTgUsername] = useState(null);

  const [aurasQuantity, setAurasQuantity] = useState(0);

  const [spinsCountUntilLucky, setSpinsUntilLucky] = useState(10);

  function countMatchingTitles(array1, array2) {
    return array1.filter((item1) =>
      array2.some((item2) => item1.title === item2.title)
    ).length;
  }

  const updateAurasQuantity = (profile) => {
    const quantity = countMatchingTitles(
      aurasData,
      profile.attributes.AurasList
    );
    setAurasQuantity(quantity);
  };

  const salary = useRef();

  const { toasts, addToast } = useToast();

  const readProfile = useCallback(async () => {
    const id = tgId || 1337;

    const fetchProfile = async () => {
      try {
        const res = await axiosInstance.get(
          `api/players?filters[tgId][$eq]=${id}&populate=AurasList&populate=ItemsList`
        );
        return res.data.data[0] || null;
      } catch (error) {
        console.error("Error fetching profile:", error);
        return null;
      }
    };

    let profile = await fetchProfile();

    if (!profile) {
      try {
        await registration();
        profile = await fetchProfile();
      } catch (error) {
        console.error(
          "Error during registration or refetching profile:",
          error
        );
      }
    }

    return profile;
  }, [tgId]);

  //////////////////РЕГИСТРАЦИЯ В БАЗЕ ДАННЫХ

  const registration = async () => {
    try {
      if (tgId === 1337) {
        await axiosInstance.post("api/players", {
          data: {
            mainAura: "Stone",
            tgId: 1337,
            firstName: "Паша",
            mainAuraRarity: 4,
            ducks: 300,
            tgUsername: tgUsername,
            bestCombo: 0,
          },
        });
      } else {
        await axiosInstance.post("api/players", {
          data: {
            mainAura: "Stone",
            tgId: tgId,
            firstName: firstName,
            mainAuraRarity: 4,
            ducks: 300,
            tgUsername: tgUsername,
            bestCombo: 0,
          },
        });
      }
    } catch (error) {
      console.error("Error registering player:", error);
    }
  };

  //// Загрузка ауры при входе

  useEffect(() => {
    const fetchTgInfo = async () => {
      try {
        if (tg.initDataUnsafe?.user) {
          setTgId(tg.initDataUnsafe.user.id);
          setFirstName(tg.initDataUnsafe.user.first_name);
          setTgUsername(tg.initDataUnsafe.user.username);

          tg.expand();
          tg.ready();
        } else {
          console.warn("Telegram user data not available. Using fallback.");
          setTgId(1337);
          setFirstName("PC-PLAYER");
          setTgUsername("@PC-PLAYER");
        }
      } catch (error) {
        console.error("Error during initialization:", error);
      }
    };

    fetchTgInfo();
  }, []);

  const sortItemsByType = (itemsList) => {
    const typeOrder = ["craft", "potion", "ring", "glove"];

    return itemsList.slice().sort((a, b) => {
      const typeA = typeOrder.indexOf(a.type);
      const typeB = typeOrder.indexOf(b.type);

      // Если оба предмета имеют тип "potion", сортируем их по timer
      if (a.type === "potion" && b.type === "potion") {
        return a.timer - b.timer;
      }

      // В противном случае сортируем по порядку typeOrder
      return typeA - typeB;
    });
  };

  const setSortedItems = (itemsList) => {
    const sortedItems = sortItemsByType(itemsList);
    setItems(sortedItems);
  };

  useEffect(() => {
    const preloadImages = async (auras) => {
      await Promise.all(
        auras.map((aura) => {
          return new Promise((resolve) => {
            const miniImg = new Image();
            miniImg.src = aura.miniimg;
            miniImg.onload = resolve;
            miniImg.onerror = resolve;
          });
        })
      );
    };

    const initializeApp = async () => {
      try {
        await preloadImages(aurasData);
        console.log("Images preloaded");
        isAuraLoaded(true);
      } catch (error) {
        console.error("Error during image preload:", error);
        isAuraLoaded(true);
      }
    };

    initializeApp();
  }, []);

  useEffect(() => {
    const update = () => {
      if (localProfile?.attributes?.AurasList) {
        updateAurasQuantity(localProfile);
      }
    };
    update();
  }, [localProfile?.attributes?.AurasList]);

  const changeMainAura = async (aura) => {
    setSoldIndex(aura.index);
    setActiveAuraIndex(null);
    const newCurrentAura = await getAuraByName(aura.title);
    await handleAurasUpdate(
      {
        title: currentAura.title,
        probability: currentAura.probability,
        quantity: 1,
      },
      { title: aura.title, quantity: 1 }
    );
    setCurrentAura(newCurrentAura);
    setSortedAuras(
      localProfile.attributes.AurasList.sort(
        (a, b) => b.probability - a.probability
      )
    );
    setSortedItems(localProfile.attributes.ItemsList);
    setActiveItem(null);
    s_mainAura.play();
  };

  useEffect(() => {
    const setLocalProfile = async () => {
      const profile = await readProfile();
      sendLocalProfileData(profile);
      setFirstName(profile.attributes.firstName);

      try {
        const aura = await getAuraByName(profile.attributes.mainAura);
        setCurrentAura(aura);
        setSortedItems(profile.attributes.ItemsList);
        fetchEquipped(profile.attributes.HandL, profile.attributes.HandR);

        setSortedAuras(
          profile.attributes.AurasList.sort(
            (a, b) => b.probability - a.probability
          )
        );
      } catch (err) {
        console.log(err);
        setCurrentAura(await getAuraByName("Stone"));
        setSortedAuras(
          profile.attributes.AurasList.sort(
            (a, b) => b.probability - a.probability
          )
        );
        setSortedItems(profile.attributes.ItemsList);
      }
    };
    if (tgId) {
      setLocalProfile();
    }
  }, [tgId]);

  ////////////////////ОБНОВЛЕНИЕ АУРЫ В БАЗЕ ДАННЫХ

  useEffect(() => {
    const fetchProfileData = async () => {
      if (currentAura && localProfile) {
        if (localProfile.attributes.mainAura !== currentAura.mainAura) {
          await setProfileAura(localProfile, currentAura);
        }
        salary.current = Math.round(Math.sqrt(currentAura.probability / 2));
      }
    };

    fetchProfileData();
  }, [currentAura]);

  ///////////////////////ОБНОВЛЕНИЕ ПРОФИЛЯ В БАЗЕ ДАННЫХ

  const setProfileAura = async (profile, aura) => {
    if (tgId === 1337) {
      await axiosInstance.put(`api/players/${profile.id}`, {
        data: {
          mainAura: aura.title,
          mainAuraRarity: aura.probability,
          tgUsername: "@PCPLAYER",
        },
      });
      return;
    }
    await axiosInstance.put(`api/players/${profile.id}`, {
      data: {
        mainAura: aura.title,
        mainAuraRarity: aura.probability,
        tgUsername: tgUsername,
      },
    });
  };

  //////////////СМЕНА ФОНА АУРЫ

  useEffect(() => {
    setAdditionalLayer("");
    if (currentAura && currentAura.probability >= 1000000) {
      setCurrentContainerStyle("current-container bg-animated-4");
      // setAdditionalLayer("/jojo-anim.webp");
      return;
    }

    if (currentAura && currentAura.probability >= 100000) {
      setCurrentContainerStyle("current-container bg-animated-3");
      return;
    }
    if (currentAura && currentAura.probability >= 50000) {
      setCurrentContainerStyle("current-container bg-animated-5");
      return;
    }
    if (currentAura && currentAura.probability >= 10000) {
      setCurrentContainerStyle("current-container bg-animated-2");
      return;
    }
    if (currentAura && currentAura.probability >= 5000) {
      setCurrentContainerStyle("current-container bg-animated");
      return;
    }

    if (currentAura && currentAura.probability >= 1000) {
      setCurrentContainerStyle("current-container bg");
      return;
    }

    setCurrentContainerStyle("current-container");
  }, [currentAura]);

  const [additionalLayer, setAdditionalLayer] = useState("");

  //////////////SPIN Кнопка

  const [spinsCount, setSpinsCount] = useState(spinsCountUntilLucky);
  const spinsCountRef = useRef(spinsCount);
  useEffect(() => {
    console.log("SPINS:", spinsCount);
    spinsCountRef.current = spinsCount;
  }, [spinsCount]);
  const [canSpin, setCanSpin] = useState(true);
  const [spinWeight, setSpinWeight] = useState(1);
  const spinWeightRef = useRef(spinWeight);
  useEffect(() => {
    spinWeightRef.current = spinWeight;
  }, [spinWeight]);
  const [baseSpinWeight, setBaseSpinWeight] = useState(0);

  const [potionSpinWeight, setPotionSpinWeight] = useState(0);
  const potionSpinWeightRef = useRef(0);

  useEffect(() => {
    potionSpinWeightRef.current = potionSpinWeight;
  }, [potionSpinWeight]);

  const [potionSpeed, setPotionSpeed] = useState(null);
  const potionSpeedRef = useRef(potionSpeed);
  useEffect(() => {
    console.log("effect: ", potionSpeed);
    potionSpeedRef.current = potionSpeed;
  }, [potionSpeed]);

  const [bonusSpinWeight, setBonusSpinWeight] = useState(0);
  const bonusSpinWeightRef = useRef(bonusSpinWeight);

  useEffect(() => {
    bonusSpinWeightRef.current = bonusSpinWeight;
  }, [bonusSpinWeight]);

  const spinSpeed = useRef(5000);

  const startSpinTimer = () => {
    s_charging.play();
    let spins = 1;
    setSpinsCount(spins);
    const interval = setInterval(() => {
      if (spins < spinsCountUntilLucky) {
        spins += 1;
        setSpinsCount(spins);
      } else {
        clearInterval(interval);
        setCanSpin(true);
        if (autoSpinRef.current) {
          setTimeout(() => {
            handleSpinAuras();
          }, 300);
        }
      }
    }, 500);
  };

  ///////ТАП по картинке
  const imageTap = () => {
    auraContainerRef.current.style.scale = "0.85";
    setTimeout(() => {
      auraContainerRef.current.style.scale = "1";
    }, 100);
  };

  ////////////SALE кнопка

  const sellAura = async () => {
    farm(spinAura.probability);
    setShowWin(false);
    setTakeButtonDisabled(true);
    s_takeAura.play();
  };

  ////////////AURAS UPDATE

  const auraUpdateQueue = createQueue();

  const [soldIndex, setSoldIndex] = useState(null);

  const handleSell = async (aura, quantity) => {
    setSoldIndex(activeItem.index);
    console.log(activeItem.index);
    await handleAurasUpdate(null, {
      title: aura.title,
      quantity: quantity,
    });
    await farm(aura.probability * quantity);
    setActiveItem(null);
  };

  const [auraAdded, setAuraAdded] = useState(false);

  const handleAurasUpdate = async (addAura, removeAura) => {
    try {
      // Проигрываем звук, блокируем кнопки
      s_takeAura.play();
      setShowWin(false);
      setTakeButtonDisabled(true);
      setAuraAdded(true);
      setTimeout(() => setAuraAdded(false), 200);

      // Добавляем задачу в очередь и обрабатываем результат через then
      auraUpdateQueue
        .add(
          () =>
            new Promise((resolve, reject) => {
              socket.emit("aurasUpdate", {
                playerId: localProfile.id,
                addAura,
                deleteAura: removeAura,
              });

              socket.once("aurasUpdateSuccess", ({ auras }) => resolve(auras)); // Успешное завершение
              socket.once("auraUpdateError", ({ message }) =>
                reject(new Error(message))
              ); // Ошибка
            })
        )
        .then((auras) => {
          // Если запрос успешен, обновляем локальные данные
          let newLocalProfile = {
            ...localProfile,
            attributes: { ...localProfile.attributes, AurasList: auras },
          };

          setSortedAuras(auras.sort((a, b) => b.probability - a.probability));
          sendLocalProfileData(newLocalProfile);
          setSoldIndex(null);
        })
        .catch((error) => {
          // Если произошла ошибка
          console.error("Failed to update auras:", error.message);
          alert("Произошла ошибка: " + error.message);
          setSoldIndex(null);
        });
    } catch (error) {
      console.error("Error in handleAurasUpdate:", error);
    }
  };

  const handleEquipmentUpdate = async (item, action) => {
    try {
      setActiveItem(null); // Сбрасываем активный предмет
      socket.emit("equipmentUpdate", {
        playerId: localProfile.id,
        item: item,
        action, // "equip" или "unequip"
      });

      // Обработка успешного обновления от сервера
      socket.once("equipmentUpdateSuccess", ({ ItemsList, HandL, HandR }) => {
        let newLocalProfile = {
          ...localProfile,
          attributes: {
            ...localProfile.attributes,
            ItemsList: ItemsList,
            HandL: HandL,
            HandR: HandR,
          },
        };

        setSortedItems(ItemsList);
        fetchEquipped(HandL, HandR);
        sendLocalProfileData(newLocalProfile); // Обновляем локальный профиль
        console.log(
          "Equipment successfully updated on server and local profile."
        );
      });

      // Обработка ошибки на сервере
      socket.once("equipmentUpdateError", ({ message }) => {
        console.error("Error updating equipment on server:", message);
        addToast(message, "error");
      });
    } catch (error) {
      console.error("Error in handleEquipmentUpdate:", error);
    }
  };

  const [takeButtonText, setTakeButtonText] = useState("take");

  ///////////смена цвета ника

  const [bgColor, setBgColor] = useState("#1f1f1f");
  const [textColor, setTextColor] = useState("#7a7a7a");

  const [nameTimer, setNameTimer] = useState("");

  const handleNameClick = () => {
    const newBgColor = getRandomColor();
    if (nameTimer) {
      clearTimeout(nameTimer);
    }

    setBgColor(newBgColor);
    setTextColor("#fff");

    const timer = setTimeout(() => {
      setBgColor("#1f1f1f");
      setTextColor("#7a7a7a");
    }, 5000);
    setNameTimer(timer);
  };

  ///////////////////ТЕЛО

  // <div
  //           style={{
  //             color: "white",
  //             padding: "30px 30px",
  //           }}
  //         >
  //           <span
  //             style={{
  //               display: "flex",
  //               textAlign: "center",
  //               marginBottom: "30px",
  //             }}
  //           >
  //             Играть можно только с телефона
  //           </span>
  //           <button className="button-spin">
  //             <a
  //               style={{ textDecoration: "none", color: "black" }}
  //               href="https://t.me/aura_rng_bot"
  //             >
  //               AURA-RNG
  //             </a>
  //           </button>
  //         </div>

  const [clickCount, setClickCount] = useState(0);
  const [comboClicks, setComboClicks] = useState(0);
  const clickSalary = useRef(0);
  const [coinCounterOpacity, setClickCounterOpacity] = useState(0);
  const [auraOpacity, setAuraOpacity] = useState(1);

  const [coinCounterTimer, setCoinCounterTimer] = useState("");
  const [maxClicks, setMaxClicks] = useState(5);

  const [isMiniGameActive, setIsMiniGameActive] = useState(false);

  const startMiniGame = () => {
    s_gameBg.play();
    setIsMiniGameActive(true);
    setClickCounterOpacity(1);
    setComboClicks(0);
    setAuraOpacity(0.3);
  };

  const endMiniGame = () => {
    setTimeout(() => {
      s_gameBg.stop();
      setIsMiniGameActive(false);
      setAuraOpacity(1);
      s_tapGameLoose.play();
    }, 100);
    if (comboClicks > localProfile.attributes.bestCombo) {
      socket.emit("updateBestCombo", {
        playerId: localProfile.id,
        combo: comboClicks,
      });
      localProfile.attributes.bestCombo = comboClicks;
    }
  };

  const handleScore = () => {
    setClickCount((prevCount) => prevCount + 1);
    const randomNumber = Math.random() * (1.5 - 0.8) + 0.8;
    s_tapGamePoint.rate(randomNumber);
    s_tapGamePoint.play();
    setComboClicks((prevCount) => prevCount + 1);
    imageTap();
  };

  useEffect(() => {
    const gameScoring = () => {
      if (clickCount >= maxClicks) {
        setClickCount(0);
        let multiplyer = 1 + comboClicks / 70;
        clickSalary.current = Math.round(salary.current * multiplyer);

        farm(clickSalary.current);

        const clickEvent = new CustomEvent("luck-click");
        document.dispatchEvent(clickEvent);
      }

      setClickCounterOpacity(1);
      if (coinCounterTimer) {
        clearTimeout(coinCounterTimer);
      }

      const timer = setTimeout(() => {
        setClickCount(0);
        setClickCounterOpacity(0);
      }, 2000);
      setCoinCounterTimer(timer);
    };
    gameScoring();
  }, [clickCount]);

  const auraContainerRef = useRef();

  ///////////////////НОВЫЙ СПИН

  const aurasLength = 30;

  const ZERO = (120 * aurasLength + 6 * aurasLength - 1) / 2;
  const [spinTransform, setSpinTransform] = useState("");
  const [showWin, setShowWin] = useState(false);
  const isSpinAuraNewRef = useRef(false);

  const handleSpinAuras = (e) => {
    if (spinning || !canSpin || showWin) {
      s_spinError.play();
      if (e) {
        pop(e);
      }
      return;
    }

    socket.emit("addSpin", {
      playerId: localProfile.id,
    });
    socket.once("addSpinSuccess", ({ newSpinsCount }) => {
      // Обновляем локальный профиль и список аур после подтверждения
      let newLocalProfile = {
        ...localProfile,
        attributes: { ...localProfile.attributes, spins: newSpinsCount },
      };
      sendLocalProfileData(newLocalProfile);
    });

    s_spin2.play();
    spinSound.play();
    let auraList = localProfile.attributes.AurasList || [];

    if (e) {
      Ripple(e);
    }
    let isAuraNew;

    setSpinsCount((current) => current - 1);
    setSpinTransform(ZERO - 252);
    setSpinning(true);
    sendSpinning(true);
    let auraArr = [];
    for (let i = 0; i < aurasLength; i++) {
      let aura = getRandomAura(spinWeightRef.current);
      let rarity = getRarity(aura.probability);
      let rarityColor = getRarityColor(rarity);
      aura = { ...aura, key: i, rarity: rarity, rarityColor: rarityColor };
      auraArr.push(aura);
    }
    setRandomAuras(auraArr);
    const randomPosition = Math.random() * 500 * 2 + 2500;
    const card = auraArr[Math.floor(randomPosition / 126)];
    const index = auraList.findIndex((aura) => card.title === aura.title);
    if (index === -1 && currentAura.title !== card.title) {
      isSpinAuraNewRef.current = true;
      isAuraNew = true;
    } else {
      isSpinAuraNewRef.current = false;
      isAuraNew = false;
    }

    setTimeout(() => {
      spinSound.stop();
      const randomNumber = Math.random() * (1.3 - 0.8) + 0.8;
      s_newAura.rate(randomNumber);
      isAuraNew ? s_newAura2.play() : s_newAura.play();

      sendSpinning(false);

      setSpinAura(card);
      console.log(card);
      setTakeButtonDisabled(false);
      setShowWin(true);
      setSpinning(false);

      if (spinsCountRef.current <= 0) {
        if (autoSpinRef.current) {
          setTimeout(() => {
            handleAurasUpdate(
              {
                title: spinAura.title,
                probability: spinAura.probability,
              },
              null
            );
          }, 1000);
        }
        startSpinTimer();
        setCanSpin(false);
        setBonusSpinWeight(0);

        return;
      }
      if (autoSpinRef.current) {
        setTimeout(() => {
          if (spinsCountRef.current > 1) {
            handleAurasUpdate(
              {
                title: card.title,
                probability: card.probability,
              },
              null
            );
            setTimeout(() => {
              handleSpinAuras();
            }, 100);
            return;
          }
          if (spinsCountRef.current === 1) {
            if (handR?.bonusSpinLuck > 0) {
              setBonusSpinWeight(handR.bonusSpinLuck);
            } else {
              setBonusSpinWeight(10);
            }
            s_useItem.play();

            handleAurasUpdate(
              {
                title: card.title,
                probability: card.probability,
              },
              null
            );
            setTimeout(() => {
              handleSpinAuras();
            }, 100);
          }
        }, 500);
      } else {
        if (spinsCountRef.current === 1) {
          if (handR?.bonusSpinLuck > 0) {
            setBonusSpinWeight(handR.bonusSpinLuck);
          } else {
            setBonusSpinWeight(10);
          }
          s_useItem.play();

          return;
        }
      }
    }, spinSpeed.current + 200);

    setTimeout(() => {
      setSpinTransform(ZERO - randomPosition);
    }, 10);
  };

  //////////////////////////CITY

  const openCity = () => {
    if (!spinning && !showWin) {
      if (
        nowOpen !== "city" &&
        nowOpen !== "shop" &&
        nowOpen !== "blacksmith" &&
        nowOpen !== "tailor"
      ) {
        setNowOpen("city");
        s_city.play();
        s_auraBg.stop();
      } else {
        setNowOpen(null);
        s_tailor.stop();
        s_blacksmith.stop();
        s_shopBg.stop();
        s_city.stop();
        s_skeleton.stop();
        s_auraBg.play();
      }
      s_click01.play();
    }
  };

  //////////////////////////////TAILOR

  const openTailor = () => {
    if (!spinning && !showWin) {
      s_click01.play();
      s_tailor.play();
      s_city.stop();
      setNowOpen("tailor");
    }
  };

  const [activeCraftItem, setActiveCraftItem] = useState(null);
  const [activeCraftItemInfo, setActiveCraftItemInfo] = useState(null);

  ///////////////////////ALCHEMIST

  const openAlchemist = () => {
    if (!spinning && !showWin) {
      s_click01.play();
      s_skeleton.play();
      s_city.stop();
      setNowOpen("alchemist");
    }
  };

  //////////////////////////BLACKSMITH

  const openBlacksmith = () => {
    if (!spinning && !showWin) {
      s_click01.play();
      s_blacksmith.play();
      s_city.stop();
      setNowOpen("blacksmith");
    }
  };

  const [itemInfoList, setItemInfoList] = useState([]);

  useEffect(() => {
    const currentItem = activeCraftItem;
    async function fetchItems() {
      const craftItemInfo = await getItemByName(currentItem.title);
      setActiveCraftItemInfo(craftItemInfo);

      const items = await Promise.all(
        currentItem.needItems.map(async (item) => {
          let itemInfo;
          if (item.type === "aura") {
            itemInfo = await getAuraByName(item.title);
          } else {
            itemInfo = await getItemByName(item.title);
          }
          return { ...item, itemInfo };
        })
      );
      setItemInfoList(items);
      console.log(items);
    }
    if (currentItem != null) {
      fetchItems();
    }
  }, [activeCraftItem]);

  const hasAllRequiredItems = (requiredItems) => {
    const aurasList = localProfile.attributes.AurasList;
    const itemsList = localProfile.attributes.ItemsList;
    const { spins, bestCombo } = localProfile.attributes;

    return requiredItems.every((requiredItem) => {
      if (requiredItem.type === "aura") {
        // Проверка для аур
        const matchingAura = aurasList.find(
          (aura) => aura.title === requiredItem.title
        );
        return matchingAura && matchingAura.quantity >= requiredItem.quantity;
      } else if (requiredItem.type === "stats") {
        // Проверка для статистики
        if (requiredItem.title === "spins") {
          return spins >= requiredItem.quantity;
        } else if (requiredItem.title === "bestCombo") {
          return bestCombo >= requiredItem.quantity;
        }
        return false; // Если не поддерживается
      } else {
        // Проверка для предметов
        const matchingItem = itemsList.find(
          (item) => item.title === requiredItem.title
        );
        return matchingItem && matchingItem.quantity >= requiredItem.quantity;
      }
    });
  };

  const hasRequiredItem = (requiredItem) => {
    const { AurasList, ItemsList, spins, bestCombo } = localProfile.attributes;

    if (requiredItem.type === "aura") {
      // Проверка для ауры
      const matchingAura = AurasList.find(
        (aura) => aura.title === requiredItem.title
      );
      return matchingAura && matchingAura.quantity >= requiredItem.quantity;
    } else if (requiredItem.type === "stats") {
      // Проверка для статистики
      if (requiredItem.title === "spins") {
        return spins >= requiredItem.quantity;
      } else if (requiredItem.title === "bestCombo") {
        return bestCombo >= requiredItem.quantity;
      }
      return false; // Если не поддерживается
    } else {
      // Проверка для предмета
      const matchingItem = ItemsList.find(
        (item) => item.title === requiredItem.title
      );
      return matchingItem && matchingItem.quantity >= requiredItem.quantity;
    }
  };

  const [craftingAnimation, setCraftingAnimation] = useState(false);

  const handleCraft = () => {
    const activeItem = activeCraftItem;
    if (hasAllRequiredItems(activeItem.needItems)) {
      setCraftingAnimation(true);
      if (activeItem.type === "glove") {
        s_sewing.play();
      } else if (activeItem.type === "ring" || activeItem.type === "craft") {
        s_forging.play();
      } else if (activeItem.type === "potion") {
        s_craftPotion.play();
      }

      console.log("CRAFT");
      socket.emit("craftItem", {
        playerId: localProfile.id,
        itemToCraft: {
          title: activeItem.title,
          cost: activeItem.cost,
          type: activeItem.type, // Указываем тип создаваемого предмета
          needItems: activeItem.needItems,
        },
      });

      socket.once("craftItemSuccess", (data) => {
        setTimeout(() => {
          setCraftingAnimation(false);
          addToast(t(activeItem.title) + " " + t("received"), "info");
          s_takeAura.play();
        }, 2500);

        const { ItemsList, AurasList, ducks } = data; // Получаем обновленные списки и валюту
        let newLocalProfile = {
          ...localProfile,
          attributes: {
            ...localProfile.attributes,
            ItemsList, // Обновляем ItemsList
            AurasList, // Обновляем AurasList
            ducks, // Обновляем количество DUCKS
          },
        };

        setSortedItems(ItemsList); // Обновляем ItemsList в состоянии
        setSortedAuras(AurasList.sort((a, b) => b.probability - a.probability));

        sendLocalProfileData(newLocalProfile); // Отправляем локальный профиль
        console.log("Crafting successful. Local profile updated.");
      });

      socket.once("craftItemError", (data) => {
        setCraftingAnimation(false);
        addToast(data.message, "error");
      });
    } else {
      addToast("Нехватает предметов", "error");
    }
  };

  /////////////////////ЧТО ОТКРЫТО СЕЙЧАС

  const [nowOpen, setNowOpen] = useState(null);

  //////////////////////SHOP

  const [shopPhrase, setShopPhrase] = useState(
    "пРИВ! дабро пожалть в лавку Эби"
  );

  const [activeShopItem, setActiveShopItem] = useState(null);
  const [activeShopItemInfo, setActiveShopItemInfo] = useState(null);
  const [activeShopItemQuantity, setActiveShopItemQuantity] = useState(1);
  const [shopBuyButtonDisabled, setShopBuyButtonDisabled] = useState(false);

  useEffect(() => {
    const currentItem = activeShopItem;
    async function fetchInfo() {
      const shopItemInfo = await getItemByName(currentItem.title);
      setActiveShopItemInfo(shopItemInfo);
      console.log(shopItemInfo);
    }
    if (currentItem != null) {
      fetchInfo();
    }
  }, [activeShopItem]);

  useEffect(() => {
    sendNowOpen(nowOpen);
    // Если здесь не требуется дополнительная логика очистки, функция возврата не нужна
  }, [nowOpen]);

  const openShop = () => {
    if (!spinning && !showWin) {
      setNowOpen("shop");

      s_shopBg.play();
      s_click01.play();
      s_city.stop();
      setShopPhrase(t("пРИВ! дабро пожалть в лавку Эби"));
    }
  };

  const handlePick = (potion) => {
    try {
      socket.emit("purchaseItem", {
        playerId: localProfile.id,
        item: potion,
        cost: 0,
      });

      // Обработка успешного обновления от сервера
      socket.once("purchaseSuccess", (data) => {
        s_takeAura.play();

        const { items, ducks } = data;
        let newLocalProfile = {
          ...localProfile,
          attributes: {
            ...localProfile.attributes,
            ItemsList: items, // обновляем ItemsList
          },
        };

        setSortedItems(items);
        sendLocalProfileData(newLocalProfile);
        console.log("Items successfully updated on server and local profile.");
      });

      // Обработка ошибки на сервере
      socket.once("purchaseError", ({ message }) => {
        console.error("Error updating items on server:", message);
      });
    } catch (error) {
      console.error("Error in purchaseItem:", error);
    }
  };

  const handleBuy = () => {
    try {
      setShopBuyButtonDisabled(true);
      setActiveShopItemQuantity(1);
      console.log("Emitting purchaseItem:", {
        playerId: localProfile.id,
        item: { title: activeShopItem.title, quantity: activeShopItemQuantity },
        cost: activeShopItem.cost * activeShopItemQuantity,
      });

      socket.emit("purchaseItem", {
        playerId: localProfile.id,
        item: {
          title: activeShopItem.title,
          quantity: activeShopItemQuantity,
          type: activeShopItem.type,
        },
        cost: activeShopItem.cost * activeShopItemQuantity,
      });

      // Обработка успешного обновления от сервера
      socket.once("purchaseSuccess", (data) => {
        s_coins.play();
        const randomPhrase = Math.floor(Math.random() * shopBuyPhrases.length);
        setShopPhrase(t(shopBuyPhrases[randomPhrase]));
        const { items, ducks } = data;
        let newLocalProfile = {
          ...localProfile,
          attributes: {
            ...localProfile.attributes,
            ItemsList: items, // обновляем ItemsList
            ducks: ducks, // обновляем ducks
          },
        };
        setShopBuyButtonDisabled(false);
        setActiveShopItem(null);
        setSortedItems(items);
        sendLocalProfileData(newLocalProfile);
        console.log("Items successfully updated on server and local profile.");
        addToast(t(activeShopItem.title) + " " + t("received"), "info");
      });

      // Обработка ошибки на сервере
      socket.once("purchaseError", ({ message }) => {
        setShopBuyButtonDisabled(false);
        setActiveShopItem(null);
        console.error("Error updating items on server:", message);
        // Разблокировка интерфейса и возможное сообщение об ошибке

        const randomPhrase = Math.floor(
          Math.random() * shopNotEnoughPhrases.length
        );
        setShopPhrase(t(shopNotEnoughPhrases[randomPhrase]));
        s_nope.play();
      });
    } catch (error) {
      console.error("Error in purchaseItem:", error);
    }
  };

  //////////////////////ИТЕМЫ ИНВЕНТАРЬ

  useEffect(() => {
    const changeSpinWeight = () => {
      setSpinWeight(
        baseSpinWeight +
          bonusSpinWeightRef.current +
          potionSpinWeightRef.current
      );
    };
    changeSpinWeight();
  }, [baseSpinWeight, bonusSpinWeight, potionSpinWeight]);

  const [activeItem, setActiveItem] = useState(null);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (activeItem) {
      console.log(activeItem);
    }
  }, [activeItem]);
  const [handL, setHandL] = useState({});
  const [handR, setHandR] = useState({});

  useEffect(() => {
    let speedBonus = 5000;

    if (handL && handL.speedBonus > 0) {
      speedBonus = speedBonus - handL.speedBonus;
    }
    if (potionSpeedRef.current > 0) {
      speedBonus = speedBonus - potionSpeedRef.current;
    }
    if (speedBonus > 0) {
      spinSpeed.current = speedBonus;
    } else {
      spinSpeed.current = 100;
    }
    console.log("POT:", potionSpeedRef.current);
    console.log("ALL:", spinSpeed.current);
  }, [handL, potionSpeed]);

  useEffect(() => {
    const changeHandStats = () => {
      if (handR && handR.spinsCount) {
        setSpinsUntilLucky(handR.spinsCount);
        setSpinsCount(handR.spinsCount);
      } else {
        setSpinsUntilLucky(10);
        setSpinsCount(10);
      }
    };
    changeHandStats();
  }, [handR]);

  const fetchEquipped = async (handL, handR) => {
    let leftInfo, rightInfo;
    if (handL) {
      leftInfo = await getItemByName(handL);
      leftInfo = { ...leftInfo, equipped: true };
      setBaseSpinWeight(leftInfo.luckBonus);
    } else {
      setBaseSpinWeight(0);
    }
    if (handR) {
      rightInfo = await getItemByName(handR);
      rightInfo = { ...rightInfo, equipped: true };
    }

    leftInfo ? setHandL(leftInfo) : setHandL(null);
    rightInfo ? setHandR(rightInfo) : setHandR(null);
  };

  const activeItemDetails = async (item, quantity) => {
    let details = await getItemByName(item.title);
    if (quantity) {
      details = { ...details, quantity: quantity };
    }
    setActiveItem(details);
  };

  const openItems = () => {
    if (!spinning && !showWin) {
      s_click01.play();

      setActiveItem(null);
      if (nowOpen !== "items") {
        setNowOpen("items");
        s_tailor.stop();
        s_blacksmith.stop();
        s_shopBg.stop();
        s_city.stop();
        s_skeleton.stop();
        if (!s_auraBg.playing()) {
          s_auraBg.play();
        }
      } else {
        setNowOpen(null);
      }
    }
  };

  const [itemsStack, setItemsStack] = useState(null);
  const itemsStackQuantity = useRef(itemsStack?.quantity);

  useEffect(() => {
    if (itemsStack) {
      console.log(itemsStack);

      itemsStackQuantity.current = itemsStack.quantity;
    }
  }, [itemsStack]);

  const applyAllItems = async (item) => {
    applyItem(item, item.quantity);
    setActiveItem(null);
  };

  const [activePotions, setActivePotions] = useState([]);

  const applyItem = async (item, quantity) => {
    const existingPotion = activePotions.find(
      (potion) => potion.title === item.title
    );

    if (!existingPotion) {
      // Если зелье еще не активно, добавляем его
      const endTime = Date.now() + item.timer * 60 * 1000 * quantity;

      const timerId = setInterval(() => {
        setActivePotions(
          (prev) =>
            prev
              .map((potion) => {
                if (potion.title === item.title) {
                  const remainingTime = Math.max(
                    0,
                    Math.ceil((potion.endTime - Date.now()) / 1000)
                  );

                  if (remainingTime <= 0) {
                    clearInterval(potion.timerId); // Очищаем только свой таймер
                    return null; // Флаг для удаления
                  }

                  return { ...potion, remainingTime };
                }
                return potion;
              })
              .filter(Boolean) // Удаляем закончившееся зелье
        );
      }, 1000);

      const newPotion = {
        title: item.title,
        color: item.color,
        miniimg: item.miniimg,
        endTime,
        remainingTime: Math.ceil((endTime - Date.now()) / 1000),
        timerId,
        ...("multiplyer" in item && { multiplyer: item.multiplyer }),
        ...("speedBonus" in item && { speedBonus: item.speedBonus }),
      };

      setActivePotions((prev) => [...prev, newPotion]);
    } else {
      // Если зелье уже активно, увеличиваем время окончания
      setActivePotions((prev) =>
        prev.map((potion) =>
          potion.title === item.title
            ? {
                ...potion,
                endTime: potion.endTime + item.timer * 60 * 1000 * quantity,
                remainingTime: Math.ceil(
                  (potion.endTime +
                    item.timer * 60 * 1000 * quantity -
                    Date.now()) /
                    1000
                ),
              }
            : potion
        )
      );
    }
  };

  useEffect(() => {
    const updateRemainingTimes = setInterval(() => {
      setActivePotions((prev) => {
        const updatedPotions = prev.map((potion) => ({
          ...potion,
          remainingTime: Math.max(
            0,
            Math.ceil((potion.endTime - Date.now()) / 1000)
          ),
        }));

        // Проверяем, изменились ли данные
        const isChanged =
          JSON.stringify(prev) !== JSON.stringify(updatedPotions);
        return isChanged ? updatedPotions : prev;
      });
    }, 1000);

    return () => clearInterval(updateRemainingTimes);
  }, []);

  useEffect(() => {
    // Определяем максимальные значения multiplyer и speedBonus
    const { maxMultiplyer, maxSpeedBonus } = activePotions.reduce(
      (totals, potion) => ({
        maxMultiplyer: Math.max(totals.maxMultiplyer, potion.multiplyer || 0),
        maxSpeedBonus: Math.max(totals.maxSpeedBonus, potion.speedBonus || 0),
      }),
      { maxMultiplyer: 0, maxSpeedBonus: 0 } // Начальные значения
    );

    // Устанавливаем только максимальные значения
    setPotionSpinWeight(maxMultiplyer);
    setPotionSpeed(maxSpeedBonus);
  }, [activePotions]);

  const handleItemRemove = async (item, quantity) => {
    try {
      setActiveItem(null);

      const fetchedItem = await getItemByName(item.title);

      socket.emit("itemsUpdate", {
        playerId: localProfile.id,
        itemToUse: { ...item, quantity }, // Передаем количество
        action: "remove",
      });

      // Применение эффектов предмета на клиенте
      if (fetchedItem.multiplyer && fetchedItem.multiplyer > 0) {
        setPotionSpinWeight(fetchedItem.multiplyer * quantity);
      }
      if (fetchedItem.speedBonus && fetchedItem.speedBonus > 0) {
        setPotionSpeed(fetchedItem.speedBonus * quantity);
      }

      if (item.type === "potion") {
        s_usePotion.play();
      } else {
        s_useItem.play();
      }

      // Обработка успешного обновления
      socket.once("itemsUpdateSuccess", ({ ItemsList }) => {
        const newLocalProfile = {
          ...localProfile,
          attributes: { ...localProfile.attributes, ItemsList },
        };
        setSortedItems(ItemsList);
        sendLocalProfileData(newLocalProfile);
        applyItem(item, quantity);

        console.log("Items successfully updated on server and local profile.");
      });

      // Обработка ошибок
      socket.once("itemsUpdateError", ({ message }) => {
        console.error("Error updating items on server:", message);
        alert("Произошла ошибка при обновлении предметов: " + message);
      });
    } catch (error) {
      console.error("Error in handleItemRemove:", error);
    }
  };

  //////////////////////////АУРЫ ИНВЕНТАРЬ

  const openAuras = () => {
    if (!spinning && !showWin) {
      s_click01.play();
      setActiveAuraIndex(null);
      s_tailor.stop();
      s_blacksmith.stop();
      s_city.stop();
      s_skeleton.stop();
      if (nowOpen !== "auras") {
        setNowOpen("auras");
        s_tailor.stop();
        s_blacksmith.stop();
        s_shopBg.stop();
        s_city.stop();
        if (!s_auraBg.playing()) {
          s_auraBg.play();
        }
      } else {
        setNowOpen(null);
      }
    }
  };

  const [showSellQuantity, setShowSellQuantity] = useState(false);
  const [activeAuraIndex, setActiveAuraIndex] = useState(null);

  const [sortedAuras, setSortedAuras] = useState([]);

  const [randomAuras, setRandomAuras] = useState([]);

  const [autoSpin, setAutoSpin] = useState(false);
  const autoSpinRef = useRef(autoSpin);

  useEffect(() => {
    autoSpinRef.current = autoSpin;
    if (autoSpin) {
      handleSpinAuras();
    }
  }, [autoSpin]);

  return (
    <>
      {spinning && (
        <SpinningWindow
          {...{
            motion,
            t,
            spinWeight,
            spinTransform,
            randomAuras,
            handL,
            spinSpeed,
          }}
        ></SpinningWindow>
      )}
      <AnimatePresence>
        {showWin && (
          <>
            <motion.div
              animate={{
                opacity: 1,
              }}
              exit={{ opacity: 0 }}
              className="show-win-backdrop"
            ></motion.div>
            <div className="show-win">
              <motion.div
                style={{ outline: `2px solid ${spinAura.rarityColor} ` }}
                className="show-win-container"
                initial={{
                  opacity: 0,
                  scale: 0.9,
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                }}
                exit={{ scale: 0.8, opacity: 0 }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                  duration: 0.1,
                }}
              >
                <div
                  style={{
                    backgroundColor: spinAura.rarityColor,
                  }}
                  className="show-win-name"
                >
                  <h3>{t(spinAura.title)}</h3>
                  <p>{t(spinAura.rarity)}</p>
                </div>
                <h3 className="show-win-probability">
                  1/{spinAura.probability}
                </h3>
                <div className="show-win-image-container">
                  <img
                    alt="star-bg"
                    className="win-glowing-bg-star"
                    src="/glowing-star2.webp"
                  ></img>
                  <Player
                    autoplay
                    loop
                    src={"stars.json"}
                    style={{
                      position: "absolute",
                      width: "100%",
                      top: "100px",
                      left: 0,

                      zIndex: 10, // Убедитесь, что анимация выше остальных элементов
                    }}
                  ></Player>
                  {spinAura?.webm && (
                    <motion.div
                      initial={{
                        opacity: 0,
                        scale: 0.8,
                      }}
                      animate={{
                        opacity: 1,
                        scale: 1,
                      }}
                      transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20,
                        duration: 0.3,
                      }}
                    >
                      <video
                        style={{ width: "100%" }}
                        src={spinAura.img}
                        autoPlay
                        loop
                        muted
                      />
                    </motion.div>
                  )}
                  {!spinAura?.webm && (
                    <motion.img
                      initial={{
                        opacity: 0,
                        scale: 0.8,
                      }}
                      animate={{
                        opacity: 1,
                        scale: 1,
                      }}
                      transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20,
                        duration: 0.3,
                      }}
                      className="win-image"
                      alt="win"
                      src={spinAura.img}
                    ></motion.img>
                  )}

                  <div className="win-price"></div>
                </div>
                {isSpinAuraNewRef.current && (
                  <div className="show-win-new">{t("new")}</div>
                )}
              </motion.div>
            </div>
          </>
        )}
      </AnimatePresence>
      <div className="main-ui">
        {nowOpen === "auras" && (
          <AurasInventory
            {...{
              t,
              aurasQuantity,
              aurasData,
              motion,
              setActiveAuraIndex,
              sortedAuras,
              soldIndex,
              setActiveItem,
            }}
          ></AurasInventory>
        )}
        {nowOpen === "items" && (
          <ItemsInventory
            {...{
              t,
              motion,
              items,
              activeItemDetails,
              setActiveItem,
              handL,
              handR,
            }}
          ></ItemsInventory>
        )}
        {activeItem != null && (
          <ActiveItem
            {...{
              changeMainAura,
              activeItem,
              setActiveItem,
              handleItemRemove,
              handleSell,
              motion,
              handleEquipmentUpdate,
              applyAllItems,
              t,
            }}
          ></ActiveItem>
        )}
        {nowOpen === "shop" && (
          <>
            <div className="inventory-title">
              <div>{t("AbigailPlace")}</div>
              <TakeIcon
                onClick={() => {
                  setNowOpen("city");
                  s_shopBg.stop();
                  s_city.play();
                }}
                className="back-icon"
                fill="#e0e0e0dc"
                size="20px"
              ></TakeIcon>
            </div>
            <div className="shop-image">
              <div className="shop-dialog">{t(shopPhrase)}</div>
            </div>
            <motion.div
              initial={{ x: 30, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              className="inventory-container add-ui-container"
            >
              {shopItems.length > 0 && (
                <div>
                  <div className="shop-items-wrapper">
                    {shopItems.map((item, index) => (
                      <div
                        style={{ backgroundColor: item.color }}
                        key={index}
                        onClick={() => {
                          setActiveShopItem(item);
                          s_buttonClick.play();
                        }}
                        className="shop-item-container"
                      >
                        <img
                          className="shop-item-img"
                          src={item.miniimg}
                          alt="miniaura"
                        ></img>
                        <div className="shop-item-title">{t(item.title)}</div>
                        <div className="shop-item-price">
                          {item.cost}
                          <DucksIcon size={"16px"}></DucksIcon>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </motion.div>
            {activeShopItem != null && (
              <>
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="buy-confirm-window"
                >
                  <div className="buy-confirm-wrapper">
                    <div style={{ opacity: 0.5, marginBottom: "30px" }}>
                      {t("Purchase details")}
                    </div>
                    <img
                      src={activeShopItem.miniimg}
                      width={"70px"}
                      alt="activeShopItem mini img"
                    ></img>
                    <div style={{ textAlign: "center" }}>
                      <div style={{ marginBottom: "5px" }}>
                        {t(activeShopItem.title)}
                      </div>
                      <div style={{ color: "yellow" }}>
                        {activeShopItemInfo?.multiplyer > 0 && (
                          <div>
                            {t("luck")} +{activeShopItemInfo.multiplyer} <br />
                            {t("duration")} {activeShopItemInfo.timer}{" "}
                            {t("min")}
                          </div>
                        )}
                        {activeShopItemInfo?.speedBonus > 0 && (
                          <div>
                            {t("spin time")} -
                            {activeShopItemInfo.speedBonus / 1000} {t("sec")}{" "}
                            <br />
                            {t("duration")} {activeShopItemInfo.timer}{" "}
                            {t("min")}
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <button
                        onClick={() => {
                          if (activeShopItemQuantity > 1) {
                            s_buttonClick.play();
                            setActiveShopItemQuantity((current) => current - 1);
                          }
                        }}
                        className="buy-buttons"
                      >
                        -
                      </button>
                      <div className="buy-confirm-quantity">
                        {activeShopItemQuantity}
                      </div>
                      <button
                        onClick={() => {
                          s_buttonClick.play();
                          if (activeShopItemQuantity < 50) {
                            setActiveShopItemQuantity((current) => current + 1);
                          }
                        }}
                        className="buy-buttons"
                      >
                        +
                      </button>
                    </div>

                    <motion.div
                      key={activeShopItemQuantity}
                      initial={{ scale: 1.1 }}
                      animate={{ scale: 1 }}
                      style={{ display: "flex", gap: "7px" }}
                    >
                      <div>
                        {t("Total: ")}
                        {activeShopItem.cost * activeShopItemQuantity}
                      </div>
                      <DucksIcon size={"20px"} />
                    </motion.div>
                    <button
                      disabled={shopBuyButtonDisabled}
                      onClick={() => handleBuy()}
                      className="button-buy"
                    >
                      {t("Buy")}
                    </button>
                  </div>
                </motion.div>
                <div
                  onClick={() => {
                    setActiveShopItem(null);
                    setActiveShopItemQuantity(1);
                  }}
                  className="black-screen"
                ></div>
              </>
            )}
          </>
        )}
        {nowOpen === "blacksmith" && (
          <Blacksmith
            {...{
              t,
              motion,
              forgeItems,
              setActiveCraftItem,
              setActiveCraftItemInfo,
              localProfile,
              activeCraftItem,
              setNowOpen,
              itemInfoList,
              handleCraft,
              activeCraftItem,
              hasAllRequiredItems,
              shopItems,
              activeCraftItemInfo,
              hasRequiredItem,
              craftingAnimation,
            }}
          ></Blacksmith>
        )}
        {nowOpen === "tailor" && (
          <>
            <div className="inventory-title">
              <div>{t("ElunaPlace")}</div>
              <TakeIcon
                onClick={() => {
                  setNowOpen("city");
                  s_tailor.stop();
                  s_city.play();
                }}
                className="back-icon"
                fill="#e0e0e0dc"
                size="20px"
              ></TakeIcon>
            </div>
            <motion.div
              initial={{ x: 30, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              className="inventory-container add-ui-container"
            >
              <div>
                <div className="blacksmith-items-wrapper">
                  {tailorItems.map((item, index) => (
                    <div
                      // style={{ backgroundColor: item.color }}
                      key={index}
                      onClick={() => {
                        setActiveCraftItem(item);
                        s_buttonClick.play();
                      }}
                      className="blacksmith-item"
                    >
                      <div
                        style={{
                          backgroundColor: hasAllRequiredItems(item.needItems)
                            ? "yellow"
                            : "red",
                        }}
                        className="blacksmith-item-circle"
                      ></div>
                      <img
                        className="blacksmith-item-img"
                        src={item.miniimg}
                        alt="miniaura"
                      ></img>

                      <div className="blacksmith-item-title">
                        {t(item.title)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
            {activeCraftItem != null && (
              <>
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="buy-confirm-window"
                >
                  <div className="forge-container">
                    <img className="forge-divider" src="Divider.webp"></img>
                    <div className="forge-top-container">
                      <img
                        className="forge-image"
                        src={activeCraftItem.miniimg}
                      ></img>
                      <div className="forge-title-container">
                        <div className="forge-title">
                          {t(activeCraftItem.title)}
                        </div>
                        <div className="forge-description">
                          {activeCraftItemInfo?.type === "glove" && (
                            <>
                              {activeCraftItemInfo.description && (
                                <span>
                                  {t(activeCraftItemInfo.description)}
                                </span>
                              )}
                              {!activeCraftItemInfo.description && (
                                <span>
                                  {t("bonus luck")}{" "}
                                  {activeCraftItemInfo?.bonusSpinLuck}{" "}
                                  {t("every")} {activeCraftItemInfo?.spinsCount}{" "}
                                  {t("spins")}
                                  <br></br>
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        width: "100%",
                        marginBottom: "14px",
                        color: "yellow",
                      }}
                    >
                      {t("Ingridients")}
                    </div>
                    <div className="forge-need-wrapper">
                      {itemInfoList.map((item, index) => {
                        return (
                          <div className="forge-need-container" key={index}>
                            <div
                              style={{
                                backgroundColor: hasRequiredItem(item)
                                  ? "yellow"
                                  : "red",
                              }}
                              className="forge-item-quantity"
                            >
                              {item.quantity}
                            </div>
                            <img
                              className="forge-need-img"
                              src={item?.itemInfo?.miniimg}
                            ></img>
                            <div className="forge-need-title">
                              {t(item.title)}
                            </div>
                          </div>
                        );
                      })}
                      <div className="forge-need-container">
                        <div
                          style={{
                            backgroundColor:
                              localProfile.attributes.ducks >=
                              activeCraftItem.cost
                                ? "yellow"
                                : "red",
                          }}
                          className="forge-item-quantity"
                        >
                          {activeCraftItem.cost}
                        </div>
                        <img
                          src="ducks-image.webp"
                          className="forge-need-img"
                        ></img>
                        <div className="forge-need-title">DUCK$</div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <button
                        onClick={() => {
                          setActiveCraftItem(null);
                          setActiveCraftItemInfo(null);
                        }}
                        className="forge-button"
                        style={{ backgroundColor: "gray", width: "30%" }}
                      >
                        {t("BACK")}
                      </button>
                      <button onClick={handleCraft} className="forge-button">
                        {t("EMBROIDER")}
                        {<TailorIcon size="24px"></TailorIcon>}
                      </button>
                    </div>
                  </div>
                </motion.div>
                <div
                  onClick={() => {
                    setActiveCraftItem(null);
                    setActiveCraftItemInfo(null);
                  }}
                  className="black-screen"
                  style={{ backgroundColor: "black", opacity: 0.6 }}
                ></div>
                {craftingAnimation && (
                  <div className="crafting-anim">
                    <Player
                      src={sewingAnim}
                      loop
                      autoplay
                      style={{ height: "250px" }}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}

        {nowOpen === "alchemist" && (
          <>
            <div className="inventory-title">
              <div>{t("ChonkPlace")}</div>
              <TakeIcon
                onClick={() => {
                  setNowOpen("city");
                  s_skeleton.stop();
                  s_city.play();
                }}
                className="back-icon"
                fill="#e0e0e0dc"
                size="20px"
              ></TakeIcon>
            </div>
            <motion.div
              initial={{ x: 30, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              className="inventory-container add-ui-container"
            >
              <div>
                <div className="blacksmith-items-wrapper">
                  {alchemistItems.map((item, index) => (
                    <div
                      // style={{ backgroundColor: item.color }}
                      key={index}
                      onClick={() => {
                        setActiveCraftItem(item);
                        s_buttonClick.play();
                      }}
                      className="blacksmith-item"
                    >
                      <div
                        style={{
                          backgroundColor: hasAllRequiredItems(item.needItems)
                            ? "yellow"
                            : "red",
                        }}
                        className="blacksmith-item-circle"
                      ></div>
                      <img
                        className="blacksmith-item-img"
                        src={item.miniimg}
                        alt="miniaura"
                      ></img>

                      <div className="blacksmith-item-title">
                        {t(item.title)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {shopItems.length === 0 && (
                <div style={{ textAlign: "center", padding: "30px 10px" }}>
                  {t("empty")}
                </div>
              )}
            </motion.div>
            {activeCraftItem != null && (
              <>
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="buy-confirm-window"
                >
                  <div className="forge-container">
                    <img className="forge-divider" src="Divider.webp"></img>
                    <div className="forge-top-container">
                      <img
                        className="forge-image"
                        src={activeCraftItem.miniimg}
                      ></img>
                      <div className="forge-title-container">
                        <div className="forge-title">
                          {t(activeCraftItem.title)}
                        </div>
                        <div className="forge-description">
                          <>
                            {activeCraftItemInfo?.multiplyer > 0 && (
                              <div>
                                {t("luck")} +{activeCraftItemInfo.multiplyer}{" "}
                                <br />
                                {t("duration")} {activeCraftItemInfo.timer}{" "}
                                {t("min")}
                              </div>
                            )}
                            {activeCraftItemInfo?.speedBonus > 0 && (
                              <div>
                                {t("spin time")} -
                                {activeCraftItemInfo.speedBonus / 1000}{" "}
                                {t("sec")} <br />
                                {t("duration")} {activeCraftItemInfo.timer}{" "}
                                {t("min")}
                              </div>
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        width: "100%",
                        marginBottom: "14px",
                        color: "yellow",
                      }}
                    >
                      {t("Ingridients")}
                    </div>
                    <div className="forge-need-wrapper">
                      {itemInfoList.map((item, index) => {
                        return (
                          <div className="forge-need-container" key={index}>
                            <div
                              style={{
                                backgroundColor: hasRequiredItem(item)
                                  ? "yellow"
                                  : "red",
                              }}
                              className="forge-item-quantity"
                            >
                              {item.quantity}
                            </div>
                            <img
                              className="forge-need-img"
                              src={item?.itemInfo?.miniimg}
                            ></img>
                            <div className="forge-need-title">
                              {t(item.title)}
                            </div>
                          </div>
                        );
                      })}
                      {activeCraftItem.cost > 0 && (
                        <div className="forge-need-container">
                          <div
                            style={{
                              backgroundColor:
                                localProfile.attributes.ducks >=
                                activeCraftItem.cost
                                  ? "yellow"
                                  : "red",
                            }}
                            className="forge-item-quantity"
                          >
                            {activeCraftItem.cost}
                          </div>
                          <img
                            src="ducks-image.webp"
                            className="forge-need-img"
                          ></img>
                          <div className="forge-need-title">DUCK$</div>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <button
                        onClick={() => {
                          setActiveCraftItem(null);
                          setActiveCraftItemInfo(null);
                        }}
                        className="forge-button"
                        style={{ backgroundColor: "gray", width: "30%" }}
                      >
                        {t("BACK")}
                      </button>
                      <button onClick={handleCraft} className="forge-button">
                        {t("BREW")}
                        {<AlchemistIcon size="24px"></AlchemistIcon>}
                      </button>
                    </div>
                  </div>
                </motion.div>
                <div
                  onClick={() => {
                    setActiveCraftItem(null);
                    setActiveCraftItemInfo(null);
                  }}
                  className="black-screen"
                  style={{ backgroundColor: "black", opacity: 0.6 }}
                ></div>
                {craftingAnimation && (
                  <div className="crafting-anim">
                    <Player
                      src={alchemistAnim}
                      loop
                      autoplay
                      style={{ height: "250px" }}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}

        {nowOpen === "city" && (
          <>
            <div className="inventory-title">
              <div>{t("Starholm")}</div>
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="inventory-container add-ui-container"
            >
              <div>
                <div className="city-block-wrapper">
                  <div
                    onClick={openShop}
                    style={{
                      backgroundImage: "url(city-images/shop2.webp)",
                    }}
                    className="city-block-container"
                  >
                    <div className="city-block-title">{t("AbigailPlace")}</div>
                    <ShopIcon size="42px"></ShopIcon>
                  </div>
                </div>
              </div>

              <div>
                <div onClick={openBlacksmith} className="city-block-wrapper">
                  <div
                    style={{
                      backgroundImage: "url(city-images/blacksmith.webp)",
                    }}
                    className="city-block-container"
                  >
                    <div className="city-block-title">{t("GatanoaPlace")}</div>
                    <BlacksmithIcon size="42px"></BlacksmithIcon>
                  </div>
                </div>
              </div>

              <div>
                <div onClick={openTailor} className="city-block-wrapper">
                  <div
                    style={{
                      backgroundImage: "url(city-images/tailor2.webp)",
                    }}
                    className="city-block-container"
                  >
                    <div className="city-block-title">{t("ElunaPlace")}</div>
                    <TailorIcon size="38px"></TailorIcon>
                  </div>
                </div>
              </div>

              <div>
                <div onClick={openAlchemist} className="city-block-wrapper">
                  <div
                    style={{
                      backgroundImage: "url(city-images/alchemist.webp)",
                    }}
                    className="city-block-container"
                  >
                    <div className="city-block-title">{t("ChonkPlace")}</div>
                    <AlchemistIcon size="42px"></AlchemistIcon>
                  </div>
                </div>
              </div>
            </motion.div>
          </>
        )}
        <>
          {currentAura && nowOpen === null && (
            <div className={currentContainerStyle}>
              <div
                style={{ backgroundColor: currentAura.color }}
                className="current-name-container"
              >
                <div className="current-name">{t(currentAura.title)}</div>
                <div
                  className="current-rarity"
                  style={{ color: currentAura.color }}
                >
                  {"1/" + currentAura.probability}
                </div>
              </div>
              <PotionContainer
                addPotionToInventory={handlePick}
                handR={handR}
              ></PotionContainer>
              {itemsStack && (
                <div
                  onClick={() => setItemsStack(null)}
                  style={{ backgroundColor: itemsStack.color }}
                  className="items-stack-container"
                >
                  <img width={50} src={itemsStack.miniimg}></img>

                  <div className="items-stack-quantity">
                    {itemsStack.quantity}
                  </div>
                </div>
              )}
              <motion.h4
                key={comboClicks}
                initial={{ y: 5 }}
                animate={{ y: 0 }}
                transition={{
                  duration: 0.1,
                  type: "spring",
                  easeInOut,
                }}
                style={{
                  opacity: comboClicks > maxClicks ? coinCounterOpacity : 0,
                  transition: ".3s ease",
                  width: "100%",
                  height: "0",
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingTop: "5px",
                }}
              >
                COMBO x{comboClicks}
              </motion.h4>

              <motion.div
                ref={auraContainerRef}
                animate={
                  currentAura?.webm ? false : { y: [0, 10], scale: 1.05 }
                }
                transition={{
                  repeat: Infinity,
                  duration: 5,
                  repeatType: "reverse",
                }}
                onTapStart={isMiniGameActive ? "" : startMiniGame} // Запуск мини-игры при тапе
                className="aura-container"
              >
                {!currentAura?.webm && (
                  <motion.img
                    style={{ opacity: 0.3 }}
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: auraOpacity }}
                    alt="auraimg"
                    className="aura-image"
                    src={`/${currentAura.img}`}
                  ></motion.img>
                )}

                {currentAura?.webm && (
                  <>
                    <video
                      className="aura-image"
                      src={currentAura.img}
                      autoPlay
                      loop
                      muted
                    />
                  </>
                )}

                {additionalLayer && (
                  <img
                    alt="additionalLayer"
                    style={{
                      height: "35vh",
                      position: "absolute",
                    }}
                    src={additionalLayer}
                  />
                )}

                <AnimatePresence>
                  {isMiniGameActive && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <MiniGame onEndGame={endMiniGame} onScore={handleScore} />
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    opacity: coinCounterOpacity,
                    transition: ".3s ease",
                  }}
                >
                  <LuckAnimation count={clickSalary.current} />
                  <ClickBar
                    color={currentAura.color}
                    clickcount={clickCount}
                    maxClicks={maxClicks}
                  />
                </div>

                <motion.div
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  key={spinWeight}
                  transition={{ type: "spring", stiffness: 100 }}
                  className="luck-bonus"
                >
                  {t("Luck")} x{spinWeight > 1 ? spinWeight : 1}
                </motion.div>
              </div>

              <div style={{ position: "absolute", zIndex: 1, bottom: "-16px" }}>
                <motion.span
                  initial={{ padding: "5px 15px" }}
                  whileTap={{
                    padding: "5px 60px",
                    rotate: -90,
                    borderRadius: "20px",
                    scale: 1.1,
                  }}
                  transition={{
                    duration: 0.1,
                    type: "spring",
                    stiffness: 150,
                  }}
                  onTap={handleNameClick}
                  style={{
                    backgroundColor: bgColor,
                    color: textColor,
                  }}
                  className="player-name"
                >
                  {localProfile && localProfile.attributes.firstName}
                </motion.span>
              </div>
              <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "absolute",
                  left: 0,
                  top: "20%",
                  gap: "6px",
                }}
              >
                {activePotions.map((potion) => (
                  <li key={potion.title}>
                    <div
                      style={{ backgroundColor: potion.color }}
                      className="items-stack-container"
                    >
                      <img width={26} src={potion.miniimg}></img>
                      <div className="items-stack-quantity">
                        {Math.floor(potion.remainingTime / 60) > 0 &&
                          `${Math.floor(potion.remainingTime / 60)}m `}
                        {potion.remainingTime % 60}s
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>

        <div className="spin-table-container">
          <div className="spin-buttons">
            <div className="bottom-buttons">
              <motion.button
                disabled={spinning || isMiniGameActive}
                onClick={openItems}
                className={
                  nowOpen === "items"
                    ? "bottom-item bottom-item-clicked"
                    : "bottom-item"
                }
              >
                <InventoryIcon size="30px"></InventoryIcon>
              </motion.button>

              <motion.button
                initial={{ scale: 1.2 }}
                animate={{ scale: auraAdded ? 1.2 : 1 }}
                transition={{ duration: 0.1 }}
                disabled={spinning || isMiniGameActive}
                onClick={openAuras}
                className={
                  nowOpen === "auras"
                    ? "bottom-item bottom-item-clicked"
                    : "bottom-item"
                }
              >
                <AurasIcon size="30px"></AurasIcon>
              </motion.button>
              <motion.button
                disabled={spinning || isMiniGameActive}
                onClick={openCity}
                className={
                  nowOpen === "city"
                    ? "bottom-item bottom-item-clicked"
                    : "bottom-item"
                }
              >
                <CityIcon size="30px"></CityIcon>
              </motion.button>
            </div>
            <div style={{ display: "flex", gap: "8px" }}>
              <motion.button
                whileTap={{ scale: 0.97 }}
                transition={{ duration: 0.1 }}
                disabled={takeButtonDisabled || autoSpin}
                className="button-sell"
                onClick={(e) => {
                  sellAura();
                  PopCoins(e, spinAura.probability);
                }}
              >
                {t("sell")}
                <SellIcon style={{ marginLeft: "5px" }} size="14px"></SellIcon>
              </motion.button>
              <motion.button
                whileTap={{ scale: 0.97 }}
                transition={{ duration: 0.1 }}
                disabled={takeButtonDisabled || autoSpin}
                className={
                  currentAura &&
                  !takeButtonDisabled &&
                  currentAura.probability < spinAura.probability
                    ? "button-take button-take-glow"
                    : "button-take"
                }
                onClick={() =>
                  handleAurasUpdate(
                    {
                      title: spinAura.title,
                      probability: spinAura.probability,
                    },
                    null
                  )
                }
              >
                {t(takeButtonText)}
                <TakeIcon style={{ marginLeft: "5px" }} size="14px"></TakeIcon>
              </motion.button>
            </div>
            <div style={{ display: "flex", gap: "8px" }}>
              {localProfile?.attributes?.status === "VIP" && (
                <motion.button
                  whileTap={{
                    scale: 0.92,
                  }}
                  transition={{ duration: 0.1 }}
                  disabled={showWin}
                  onClick={() => setAutoSpin((current) => !current)}
                  className={
                    autoSpin
                      ? "button-autospin gradient-flow"
                      : "button-autospin"
                  }
                >
                  {t("AUTO")}
                </motion.button>
              )}
              <SpinButton
                disabled={showWin || autoSpin}
                clickcount={spinsCount}
                onClick={handleSpinAuras}
                spinscountuntillucky={spinsCountUntilLucky}
                // disabled={spinning || !canSpin || showWin}
                className="button-spin"
              >
                <motion.div
                // key={spinning}
                >
                  {spinning && spinsCountRef.current > 0 && !autoSpin && (
                    <Player
                      autoplay
                      loop
                      src="https://lottie.host/0509e93b-7e8e-4492-83ec-340bbe8f545c/eN20dLixkx.json"
                      style={{ height: "33px" }}
                    ></Player>
                  )}
                  {spinsCountRef.current > 0 && autoSpin && canSpin && (
                    <Player
                      autoplay
                      loop
                      src="https://lottie.host/0509e93b-7e8e-4492-83ec-340bbe8f545c/eN20dLixkx.json"
                      style={{ height: "33px" }}
                    ></Player>
                  )}
                  {spinning && spinsCountRef.current === 0 && (
                    <Player
                      autoplay
                      loop
                      src="https://lottie.host/ae8f83eb-54a8-47fd-badc-01764f3ace5f/OJdavwCkrV.json"
                      style={{ height: "53px" }}
                    ></Player>
                  )}
                  {!spinning && canSpin && !autoSpin && t("spin")}
                  {/* {!spinning && spinsCount === 0 && t("spinx2")} */}
                  {!canSpin && t("spinloading")}
                </motion.div>
              </SpinButton>
            </div>
          </div>
        </div>

        <ToastNotification toasts={toasts} />
      </div>
    </>
  );
};

export default AuraRng;
