import aurasData from "./auras.js";
import itemsData from "./items.js";

export const getRarity = (probability) => {
  let rarity;
  if (probability >= 5000000) {
    rarity = "Shining";
  } else if (probability >= 1000000) {
    rarity = "Legendary";
  } else if (probability >= 100000) {
    rarity = "Mythical";
  } else if (probability >= 10000) {
    rarity = "Rare";
  } else if (probability >= 1000) {
    rarity = "Uncommon";
  } else {
    rarity = "Common";
  }
  return rarity;
};
export const getAuraByName = async (value) => {
  for (var i = 0; i < aurasData.length; i++) {
    if (aurasData[i]["title"] === value) {
      const rarity = getRarity(aurasData[i].probability);
      const rarityColor = getRarityColor(rarity);
      const aura = {
        ...aurasData[i],
        rarity: rarity,
        rarityColor: rarityColor,
      };
      return aura;
    }
  }
  return null;
};

export const getRarityColor = (rarity) => {
  let color;
  switch (rarity) {
    case "Shining":
      color = "#87F3B8";
      break;
    case "Legendary":
      color = "#E7BD25";
      break;
    case "Mythical":
      color = "#6640E7";
      break;
    case "Rare":
      color = "#3051DB";
      break;
    case "Uncommon":
      color = "#3CAD2B";
      break;
    case "Common":
      color = "#949494";
      break;
    default:
      color = "#949494";
      break;
  }

  return color;
};

export const getItemByName = async (value) => {
  for (var i = 0; i < itemsData.length; i++) {
    if (itemsData[i]["title"] === value) {
      return itemsData[i];
    }
  }
  return null;
};

export function getContrastColor(hex) {
  // Убираем #, если он есть
  const sanitizedHex = hex.replace("#", "");

  // Парсим RGB
  const r = parseInt(sanitizedHex.substring(0, 2), 16);
  const g = parseInt(sanitizedHex.substring(2, 4), 16);
  const b = parseInt(sanitizedHex.substring(4, 6), 16);

  // Рассчитываем относительную яркость
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Если фон светлый, возвращаем черный цвет текста, иначе белый
  return luminance > 0.5 ? "#000000e1" : "#ffffffe1";
}

export const createQueue = () => {
  let queue = [];
  let isProcessing = false;

  const processQueue = async () => {
    if (isProcessing) return;
    isProcessing = true;

    while (queue.length > 0) {
      const { task, resolve, reject } = queue.shift();
      console.log("Processing task..."); // Логируем начало обработки задачи
      try {
        const result = await task();
        console.log("Task completed successfully:", result); // Успешное выполнение
        resolve(result);
      } catch (error) {
        console.error("Task failed:", error); // Ошибка выполнения
        reject(error);
      }
    }

    isProcessing = false;
    console.log("Queue processing finished."); // Очередь обработана
  };

  return {
    add(task) {
      console.log("Task added to queue."); // Логируем добавление задачи
      return new Promise((resolve, reject) => {
        queue.push({ task, resolve, reject });
        processQueue();
      });
    },
  };
};

// Создаем очередь
const auraUpdateQueue = createQueue();
